<template>
    <page title="Retornos">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{path:'/admin/'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'financeiro'}">Financeiro</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'conta'}">Conta</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu" class="d-flex justify-space-between align-center">
            <v-menu offset-y >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="buscarRetorno">
                        <v-list-item-title>Buscar Retorno</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <div class="text-center" v-if="retornos">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

        <v-card class="mt-3">
            <v-list two-line v-if="retornos && retornos.length > 0">
                <template v-for="retorno in retornos">
                    <v-list-item two-line :to="{ name: 'retorno_view', params: { id: parseInt(retorno.id) }}">
                        <v-list-item-content>
                            <v-list-item-title>
                                Nº: {{retorno.numeroAvisoBancario}}
                                <v-chip label color="light-blue darken-3" dark x-small class="text-uppercase px-1 ml-1"  v-if="retorno.totalLiquidacoesNormais > 0">
                                    {{retorno.totalLiquidacoesNormais}} Liquidadas
                                </v-chip>
                                <v-chip label color="green" dark x-small class="text-uppercase px-1 ml-1"  v-if="retorno.totalEntradasConfirmadas > 0">
                                    {{retorno.totalEntradasConfirmadas}} Entradas Confirmadas
                                </v-chip>
                                <v-chip label color="red" dark x-small class="text-uppercase px-1 ml-1"  v-if="retorno.totalEntradasRejeitadas > 0">
                                    {{retorno.totalEntradasRejeitadas}} Entradas Rejeitadas
                                </v-chip>
                                <v-chip label color="blue-grey darken-2" dark x-small class="text-uppercase px-1 ml-1"  v-if="retorno.totalDesagendamentoDoDebitoAutomatico > 0">
                                    {{retorno.totalDesagendamentoDoDebitoAutomatico}} Desagendadas
                                </v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{retorno.totalTransacoes}} transações</v-list-item-subtitle>
<!--                            <v-list-item-subtitle class="d-flex">
                                Valor Remessa <b class="black&#45;&#45;text ml-1">{{remessa.valorTotal | formatNumber}}</b>
                                <template v-if="remessa.valorTotalPago > 0">
                                    <div class="ml-3">
                                        Valor Pago <b class="green&#45;&#45;text">{{remessa.valorTotalPago | formatNumber}}</b>
                                    </div>
                                    <div class="ml-3">
                                        Valor Faltante <b class="red&#45;&#45;text">{{remessa.valorTotal - remessa.valorTotalPago | formatNumber}}</b>
                                    </div>
                                </template>
                            </v-list-item-subtitle>-->
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>
                                {{retorno.dataGravacaoArquivo  | moment("DD MMM YYYY")}}
                            </v-list-item-action-text>
                            <v-list-item-action-text>
                                <b>Valor Pago {{retorno.valorTotalPago | formatNumber}}</b>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider ></v-divider>
                </template>
            </v-list>
            <div v-else-if="loading === false && (retornos === null || retornos.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="secondary" />
        </v-card>

        <div class="text-center mt-3" v-if="retornos">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

    </page>
</template>

<script>
import page from "@/components/Page";
import gql from "graphql-tag";

export default {
    name: "Index",
    components: {
        page
    },
    data(){
        return{
            offset: 10,
            page: 1,
            totalCount: 0,
            loading: true,
            retornos: [],
        }
    },
    methods: {
        getRetornos(){
            this.loading = true;
            this.$apollo.query({
                query: gql`query{
                    retornos{
                        id
                        numeroAvisoBancario
                        dataGravacaoArquivo
                        totalTransacoes
                        valorTotalPago
                        totalLiquidacoesNormais
                        totalEntradasConfirmadas
                        totalEntradasRejeitadas
                        totalDesagendamentoDoDebitoAutomatico
                        createdAt
                    }
                }`
            }).then(result => {
                this.retornos = result.data.retornos
            }).finally(() => {
                this.loading = false;
            })
        },
        buscarRetorno(){
            this.$apollo.mutate({
                mutation: gql`mutation {
                    iniciaTarefaBuscaRetornos{
                        id
                    }
                }`
            }).then((result) => {
                console.log("iniciaTarefaBuscaRetornos.result", result.data.iniciaTarefaBuscaRetornos);
            })
        },
        pageChanged(value){
            this.$router.push({query:{page:this.page}});
        },
    },
    mounted() {
        document.title = "Retornos - JF Assessoria Rural"
        this.getRetornos();
    }
}
</script>

<style scoped>

</style>